import minecraft from './logoF.png';
import mc64 from './mc64.png';
import poo from './poo.svg';
import bmc from './bmc.svg';
import youtube from './youtube.svg';
import facebook from './facebook.svg';
import discord from './discord.svg';
import './App.css';
import 'bulma/css/bulma.min.css';
import './App.css';
import u from './uF.png';
import n from './nF.png';
//import ReactDOM from 'react-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faXmark, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
library.add(faXmark, faCheck)


function App() {
  return (
    <div className="App">
      <header>
        <div class="container"> 
        <nav class="navbar" role="navigation" aria-label="main navigation">
          <div class="navbar-brand">
            &nbsp;&nbsp;
            <img src={mc64} width="64"/>
            <img src={minecraft} width="64"/>
            <img src={u} width="64"/>
            <img src={n} width="64"/>
            <img src={poo} width="64"/>
            <a class="navbar-item" href="#">
              
            </a>
            <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>


          <div class="navbar-end">
            <div class="navbar-item">
              <div class="field is-grouped">
              <p class="control">
                  <a class="button is-light"  href="https://www.youtube.com/@fiandfun" target="_blank">
                    <img src={youtube} />
                    <span>Youtube &nbsp;</span>
                  </a>
                </p>
                <p class="control">
                  <a class="button is-light"  href="https://www.facebook.com/profile.php?id=100093904576213" target="_blank">
                    <img src={facebook} />
                    <span>Facebook &nbsp;</span>
                  </a>
                </p>
                <p class="control">
                  <a class="button is-light"  href="https://discord.gg/P5JBskv7kq" target="_blank">
                    <img src={discord} />
                    <span>Discord &nbsp;</span>
                  </a>
                </p>
                <p class="control">
                  <a class="button is-light" href="https://www.buymeacoffee.com/ck196" target="_blank">
                    <img src={bmc} />
                    <span>Buy me a coffe</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </nav>
        </div>
      </header>

      <div>
      <div class="container">
        <div class="mg">
        </div>

        <div class="columns leftcontent">
          <div class="column">
            <div class="content">
              <h3>How to join</h3>
              <ol>
                <li>Subcribe <a class="is-light"  href="https://www.facebook.com/profile.php?id=100093904576213" target="_blank"><img src={youtube} /><strong>&nbsp;Youtube </strong></a> channel </li>
                <li>Like <a class="is-light"  href="https://facebook.com/fi4fun" target="_blank"><img src={facebook} /><strong>&nbsp;Facebook </strong> </a> Page</li>
                <li>Join <a class="is-light"  href="https://discord.gg/P5JBskv7kq" target="_blank"><img src={discord} /><strong>&nbsp;Discord </strong></a>server</li>
                <li><strong>Enjoy game</strong></li>
              </ol>
            </div>
        </div>
        </div>

        <div class="columns leftcontent">
            <div class="column">
              <div class="content">
                <h3>Server Rules</h3>
                <ol>
                  <li>24/7 <FontAwesomeIcon icon="fa-solid fa-check" style={{color: "#13e752",}} /></li>
                  <li>PvP allowed <FontAwesomeIcon icon="fa-solid fa-check" style={{color: "#13e752",}} /></li>
                  
                  <li>No hack, no cheat <FontAwesomeIcon icon="fa-solid fa-xmark" style={{color: "#f62109",}} /></li>
                  <li>No X-ray <FontAwesomeIcon icon="fa-solid fa-xmark" style={{color: "#f62109",}} /></li>
                  <li>No keep inventory <FontAwesomeIcon icon="fa-solid fa-xmark" style={{color: "#f62109",}} /></li>
                </ol>
              </div>
          </div>

          <div class="column">
            <div class="content">
              <h3>Support</h3>
              <ol>
                <li>Claim <FontAwesomeIcon icon="fa-solid fa-check" style={{color: "#0158ef",}} /></li>
                <li>Team (BetterTeam) <FontAwesomeIcon icon="fa-solid fa-check" style={{color: "#0158ef",}} /></li>
                <li>TPA (Simple TPA) <FontAwesomeIcon icon="fa-solid fa-check" style={{color: "#0158ef",}} /></li>
                <li>Back (Simple TPA) <FontAwesomeIcon icon="fa-solid fa-check" style={{color: "#0158ef",}} /></li>
                <li>Sethome <FontAwesomeIcon icon="fa-solid fa-check" style={{color: "#0158ef",}} /></li>
              </ol>
            </div>
          </div>
        </div>

        <div class="columns leftcontent">
          

        </div>
        <div class="columns">
          
          <div class="column">
          <div class="table-container">
            <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Mode</th>
                  <th>IP</th>
                  <th>Port</th>
                  <th>Max user</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Java Server 1.16.5 - 1.20
                  </td>
                  <td>
                    Survival (SMP)
                  </td>
                  <td>
                    <strong>sv.fimc.fun</strong>
                  </td>
                  <td>
                    25565
                  </td>
                  <td>
                    200
                  </td>
                </tr>

                 <tr>
                  <td>
                    Bedrock PC - PE 1.19.48 -  1.20
                  </td>
                  <td>
                    Survival (SMP)
                  </td>
                  <td>
                    <strong>sv.fimc.fun</strong>
                  </td>
                  <td>
                    19132
                  </td>
                  <td>
                    200
                  </td>
                </tr>
 
              </tbody>
            </table>
          </div>

          

          <div class="table-container">
            <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th>Donations</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Buy me a coffe
                  </td>
                  <td>
                    <a class="button is-light" href="https://www.buymeacoffee.com/ck196" target="_blank">
                      <img src={bmc} width="25"/>
                      <span>Buy me a coffe</span>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    ETH
                  </td>
                  <td>
                  0xB1a3f97AA88332c22Fb6fB7baf660651107304e1
                  </td>
                </tr>
                {/* <tr>
                  <td>
                    Bank transfer (VCB)
                  </td>
                  <td>
                    NGUYEN SY QUAN <br/>
                    STK: 0451000274107 <br/>
                    Chi nhánh: PGD Duy Tân
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>

          </div>
        </div>
      </div>


      </div>
    </div>
  );
}

export default App;
